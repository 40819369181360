<template>
  <div>
    <s-dialog :open="true" to="stds-dialog-rating-example" size="md">
      <s-dialog-overlay @click="$emit('close')" />
      <s-dialog-panel>
        <st-dialog-header class="py-16 !px-24" @clickClose="$emit('close')">
          {{ $t('studio.prj_prod_mngmt.rating_build_review.teen_prohibited_example_title') }}
        </st-dialog-header>
        <s-dialog-content class="!mx-0 !px-24">
          <s-dialog-content-body class="!mt-20 mb-24">
            <div>
              <img :src="popupImageSrc" alt="" class="w-full block" />
            </div>
            <m-table class="mt-20">
              <colgroup>
                <col style="width: 11.6rem" />
                <col style="width: auto" />
              </colgroup>
              <thead>
                <tr>
                  <st-th
                    :thTitle="
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.teen_prohibited_example_category'
                      )
                    "
                  />
                  <st-th
                    :thTitle="
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.teen_prohibited_example_description'
                      )
                    "
                  />
                </tr>
              </thead>
              <tbody>
                <st-tr>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">
                      {{
                        $t(
                          'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_sexuality'
                        )
                      }}
                    </span>
                  </st-td>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">{{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.teen_prohibited_type1_sexual_descr'
                      )
                    }}</span>
                  </st-td>
                </st-tr>

                <st-tr>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">
                      {{
                        $t(
                          'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_violence'
                        )
                      }}
                    </span>
                  </st-td>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">{{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.teen_prohibited_type3_violence_descr'
                      )
                    }}</span>
                  </st-td>
                </st-tr>
                <st-tr>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">
                      {{
                        $t(
                          'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_horror'
                        )
                      }}
                    </span>
                  </st-td>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">{{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.teen_prohibited_type3_horror_descr'
                      )
                    }}</span>
                  </st-td>
                </st-tr>
                <st-tr>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">
                      {{
                        $t(
                          'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_lang'
                        )
                      }}
                    </span>
                  </st-td>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">{{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.teen_prohibited_type4_language_descr'
                      )
                    }}</span>
                  </st-td>
                </st-tr>
                <st-tr>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">
                      {{
                        $t(
                          'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_drug'
                        )
                      }}
                    </span>
                  </st-td>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">{{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.teen_prohibited_type5_drug_descr'
                      )
                    }}</span>
                  </st-td>
                </st-tr>
                <st-tr>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">
                      {{
                        $t(
                          'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_crime'
                        )
                      }}
                    </span>
                  </st-td>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">{{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.teen_prohibited_type6_crime_descr'
                      )
                    }}</span>
                  </st-td>
                </st-tr>
                <st-tr>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">
                      {{
                        $t(
                          'studio.prj_prod_mngmt.rating_build_review.self_review.appeal_content_gambling'
                        )
                      }}
                    </span>
                  </st-td>
                  <st-td aLeft class="!py-8">
                    <span class="text-on-surface-elevation-3 text-md">{{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.teen_prohibited_type7_gambling_descr'
                      )
                    }}</span>
                  </st-td>
                </st-tr>
              </tbody>
            </m-table>
          </s-dialog-content-body>
        </s-dialog-content>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target name="stds-dialog-rating-example" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import * as PopupImages from '@/assets/images/build/28-1-3-popup';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import StTr from '@/components/common/table/st-tr.vue';

defineEmits<{
  close: [v?: boolean];
}>();

const { locale } = useI18n();
const popupImages = ref<Record<string, string>>(PopupImages);
const popupImageSrc = computed(
  () => popupImages.value[`Popup_28_01_03_${locale.value.replace('-', '_')}`]
);
</script>
