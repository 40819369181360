import {
  BUSINESS_TYPE,
  type BusinessType,
  GAME_TYPE,
  type GameType
} from '@/constants/business-bank.const';

export const getTranslationKeyByBusinessType = (businessType: BusinessType | string) => {
  switch (businessType) {
    case BUSINESS_TYPE.PERSONAL:
      return 'studio.business_bank_setting.list_biz_type_indiv';
      break;
    case BUSINESS_TYPE.INDIVIDUAL_BUSINESS:
      return 'studio.business_bank_setting.list_biz_type_indiv_biz_owner';
      break;
    case BUSINESS_TYPE.CORPORATION_BUSINESS:
    default:
      return 'studio.business_bank_setting.list_biz_type_business';
  }
};

export const getTranslationKeyByGameType = (businessType: GameType | string) => {
  switch (businessType) {
    case GAME_TYPE.DEVELOPER:
      return 'studio.business_bank_setting.individual.question6_select1';
      break;
    case GAME_TYPE.BROKERAGE:
      return 'studio.business_bank_setting.individual.question6_select2';
      break;
    case GAME_TYPE.NONE:
    default:
      return 'studio.business_bank_setting.individual.question6_select3';
  }
};
