<template>
  <div class="flex flex-col gap-12 mt-12">
    <div class="flex gap-8 items-center">
      <div class="relative w-[24rem]">
        <s-dropdown
          :key="randomIARCRegionKey"
          :value="selectedIARCRegion"
          size="lg"
          variant="line"
          :distance="0"
          :offset="[0, 0]"
          :closeOnClick="true"
          :interactive="true"
          appendTo="parent"
          placement="bottom-start"
          class="w-full"
          :isDisabled="disabled"
          @update:modelValue="onChangeIARCRegion"
        >
          <s-dropdown-button class="w-full">
            <span :class="{ 'text-placeholder': !selectedIARCRegion }">{{
              selectedIARCRegion
                ? $t(getIARCRegionTextByValue(selectedIARCRegion))
                : $t(
                  'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.select_org_dropbox_place_holder'
                )
            }}</span>
          </s-dropdown-button>
          <template #dropdownItems>
            <s-dropdown-item
              v-for="item in GLOBAL_RATING_BOARDS"
              :key="item.boardName"
              :value="item.boardName"
            >
              {{ $t(item.locale) }}
            </s-dropdown-item>
          </template>
        </s-dropdown>
      </div>
      <div class="relative flex-1">
        <s-dropdown
          v-model="selectedIARCRating"
          size="lg"
          variant="line"
          :distance="0"
          :offset="[0, 0]"
          :closeOnClick="true"
          :interactive="true"
          appendTo="parent"
          placement="bottom-start"
          class="w-full"
          :isDisabled="!selectedIARCRegion"
        >
          <s-dropdown-button class="w-full">
            <span :class="{ 'text-placeholder': !selectedIARCRating }">{{
              selectedIARCRating
                ? getIARCRatingTextByValue(selectedIARCRating, selectedIARCRegion!)
                : $t(
                  'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.select_org_dropbox_place_holder'
                )
            }}</span>
          </s-dropdown-button>
          <template #dropdownItems>
            <s-dropdown-item v-for="values in iarcRatingList" :key="values[0]" :value="values[1]">
              {{ values[0] }}
            </s-dropdown-item>
          </template>
        </s-dropdown>
      </div>
    </div>
    <div class="flex gap-16 items-center">
      <input-text
        v-model="ratingClassificationNumber"
        size="lg"
        variant="outline"
        :placeholder="
          $t(
            'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.enter_rating_reg_num_place_holder'
          )
        "
        :pattern="ALPHABE_NUMBER_HYPHEN_REGEX"
        maxLength="30"
        :allowInputMaxLength="false"
        :value="ratingClassificationNumber"
        :countable="false"
        :disabled="disabled"
      />
      <s-button
        variant="primary"
        size="sm"
        class="!min-w-84 shrink-0"
        :isDisabled="isDisabledRegisterRatingClassificationNumber"
        @click="onClickRegisterRatingClassificationNumber"
      >
        {{ $t('studio.prj_prod_mngmt.rating_build_review.direct_input.add_btn') }}
      </s-button>
    </div>
    <st-error-message :name="name" />
    <iarc-item
      v-for="(item, index) in fieldValue"
      :key="item.ratingBoardType + item.ageRating + index"
      :data="item"
      :disabled="disabled"
      @onDelete="onDeleteIarcItem(index)"
    />
  </div>
</template>
<script setup lang="ts">
import { useFieldValue } from 'vee-validate';
import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import IarcItem from '@/components/rating/mode/iarc-item.vue';
import InputText from '@/components/validation/input-text.vue';
import StErrorMessage from '@/components/validation/st-error-message.vue';
import { showAlert } from '@/composables/useDialog';
import useValidation from '@/composables/useValidation';
import { GLOBAL_RATING_BOARDS } from '@/constants/rating.const';
import { ALPHABE_NUMBER_HYPHEN_REGEX } from '@/constants/regex.const';
import type { Iarc } from '@/types/rating/rating.request.type';
import type { ValidationRule } from '@/types/validation.type';
import {
  getIARCRatingTextByValue,
  getIARCRegionTextByValue,
  getRatingOptionByValue
} from '@/utils/rating.util';

const props = defineProps<{
  isDirect: boolean;
  name: string;
  disabled?: boolean;
  noValidation?: boolean;
}>();

const { t } = useI18n();

const selectedIARCRegion = ref<string | null>();
const selectedIARCRating = ref<string | null>();
const ratingClassificationNumber = ref<string>('');
const randomIARCRegionKey = ref(0);

const { isDirect, name, noValidation } = toRefs(props);

const rules = ref<ValidationRule | undefined>(
  noValidation.value
    ? undefined
    : {
      required: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.none_rating_reg_num_val_msg1'
      )
    }
);

const { setValue } = useValidation<Iarc[]>({
  fieldName: name.value,
  rules
});

const fieldValue = useFieldValue<Iarc[]>(name.value);

const iarcRatingList = ref<[string, string][]>();

const resetInternationalOrganizationField = () => {
  randomIARCRegionKey.value += 1;
  selectedIARCRegion.value = null;
  selectedIARCRating.value = null;
  ratingClassificationNumber.value = '';
};

const onDeleteIarcItem = (index: number) => {
  fieldValue.value.splice(index, 1);
  setValue([...fieldValue.value]);
};

const onClickRegisterRatingClassificationNumber = (e: Event) => {
  if (checkExceededInputLimit()) {
    return;
  }

  e.preventDefault();
  e.stopPropagation();
  setValue([
    ...fieldValue.value,
    {
      ratingBoardType: selectedIARCRegion.value!,
      ageRating: selectedIARCRating.value!,
      ratingId: ratingClassificationNumber.value
    }
  ]);

  resetInternationalOrganizationField();
};

const checkExceededInputLimit = () => {
  let contentKey = '';
  const amountIARCRegionSelected = fieldValue.value.filter(
    (item: Iarc) => item.ratingBoardType === selectedIARCRegion.value
  ).length;
  if (!isDirect.value && amountIARCRegionSelected === 1) {
    contentKey =
      'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.rating_num_exceed_case2_popup';
  }

  if (isDirect.value && amountIARCRegionSelected === 2) {
    contentKey =
      'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.rating_num_exceed_case1_popup';
  }

  if (contentKey) {
    showAlert({
      content: t(contentKey),
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });
    return true;
  }

  return false;
};

const onChangeIARCRegion = (value: string) => {
  selectedIARCRegion.value = value;
  iarcRatingList.value = getRatingOptionByValue(value) ?? [];
  selectedIARCRating.value = null;
};

const isDisabledRegisterRatingClassificationNumber = computed(() => {
  if (selectedIARCRegion.value && selectedIARCRating.value && ratingClassificationNumber.value) {
    return false;
  }

  return true;
});
</script>
