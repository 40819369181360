<template>
  <st-box>
    <st-folding-evaluate
      :evaluateTitle="
        $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.biz_info_title')
      "
      :modifyUse="!hideGoToDetailButton"
      :modifyUseTitle="
        $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.info_edit_btn')
      "
      :isOpenDetail="true"
      @onModify="onModify"
    >
      <div
        class="grid grid-cols-2 gap-y-20 gap-x-8 bg-[var(--stds-glob-color-gray20)] rounded-3xl p-20"
      >
        <!-- @TODO : 게임 기본 정보 맵핑 -->
        <st-folding-evaluate-item
          :titleValue="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_biz_country')
          "
          :dataValue="
            businessInfos?.companyInfo?.country
              ? $t(getCountryTranslationKeyByCountryCode(businessInfos?.companyInfo?.country))
              : '-'
          "
        />
        <st-folding-evaluate-item
          :titleValue="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_biz_reg_num')
          "
          :dataValue="
            businessInfos?.companyInfo?.businessInfo?.koreaBusinessRegistrationNumber?.toString() ||
              businessInfos?.companyInfo?.businessInfo?.businessRegistrationNumber?.toString() ||
              '-'
          "
        />
        <st-folding-evaluate-item
          :titleValue="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_biz_type')
          "
          :dataValue="
            businessInfos?.companyInfo?.businessType
              ? $t(getTranslationKeyByBusinessType(businessInfos.companyInfo.businessType))
              : '-'
          "
        />
        <st-folding-evaluate-item
          :titleValue="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_biz_category')
          "
          :dataValue="
            businessInfos?.companyInfo?.corporationType
              ? $t(getTranslationKeyByGameType(businessInfos.companyInfo.corporationType))
              : '-'
          "
        />
        <st-folding-evaluate-item
          :titleValue="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_biz_name')
          "
          :dataValue="
            businessInfos?.companyInfo?.businessType !== BUSINESS_TYPE.PERSONAL &&
              businessInfos?.companyInfo?.companyName
              ? businessInfos?.companyInfo?.companyName
              : '-'
          "
        />
        <st-folding-evaluate-item
          :titleValue="
            $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_biz_representative_name'
            )
          "
          :dataValue="businessInfos?.companyInfo?.representative || '-'"
        />
      </div>
      <div
        class="grid grid-cols-2 gap-y-20 gap-x-8 bg-[var(--stds-glob-color-gray20)] rounded-3xl p-20"
      >
        <!-- @TODO : 담당자 정보 맵핑 -->
        <st-folding-evaluate-item
          :titleValue="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_pic_name')
          "
          :dataValue="ratingManager?.managerName || '-'"
        />
        <st-folding-evaluate-item
          :titleValue="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_pic_email')
          "
          :dataValue="ratingManager?.email || '-'"
        />
        <st-folding-evaluate-item
          class="col-span-2"
          :titleValue="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_pic_contact')
          "
          :dataValue="
            ratingManager
              ? `${ratingManager.phoneNumber?.countryCallingCode} ${ratingManager.phoneNumber?.phoneNumber}`
              : '-'
          "
        />
        <!-- @TODO : 그룹명 맵핑 -->
        <p class="col-span-2 text-xs font-regular leading-xs text-on-surface-elevation-4">
          <safe-html
            :html="
              $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.contact_guide', {
                grpName: selectedGroupName
              })
            "
          />
        </p>
      </div>
    </st-folding-evaluate>
  </st-box>
</template>

<script setup lang="ts">
import safeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFoldingEvaluate from '@/components/common/st-folding-evaluate.vue';
import StFoldingEvaluateItem from '@/components/common/st-folding-evaluate-item.vue';
import { useApp } from '@/composables/useApp';
import { BUSINESS_TYPE } from '@/constants/business-bank.const';
import { BUSINESS_BANK_PAGE_URL } from '@/constants/url.const';
import { MenuAuthorityIds } from '@/enums/common.enum';
import { useCountryStore } from '@/stores/country.store';
import type { BusinessInfoManagers, RatingBusinessInfo } from '@/types/rating/rating.response.type';
import {
  getTranslationKeyByBusinessType,
  getTranslationKeyByGameType
} from '@/utils/business.util';
import { redirectTo } from '@/utils/common.util';

const countryStore = useCountryStore();
const app = useApp();
const { getCountryTranslationKeyByCountryCode } = countryStore;

defineProps<{
  selectedGroupName?: string;
  businessInfos?: RatingBusinessInfo;
  ratingManager?: BusinessInfoManagers;
  hideGoToDetailButton?: boolean;
}>();

const onModify = async () => {
  if (!(await app.checkMenuWritable({ menuAuthId: MenuAuthorityIds.BIMS_BANK_SETTING }))) {
    return;
  }
  redirectTo(BUSINESS_BANK_PAGE_URL);
};
</script>
