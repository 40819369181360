<!-- 28-1-3-A popup -->
<template>
  <s-dialog to="stds-rating-survey-notify-dialog" size="md" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel>
      <s-dialog-content>
        <div class="pt-48 pb-24">
          <p class="mt-8 text-center text-lg font-regular text-on-surface-elevation-3 leading-lg">
            <safe-html
              :html="
                $t(
                  'studio.prj_prod_mngmt.rating_build_review.self_review.teenagers_prohibited_review_x_guide_popup'
                )
              "
            />
          </p>
        </div>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button variant="outline" @click="onClose">
          {{ $t('studio.common.popup_case_cancel_btn') }}
        </s-button>
        <s-button @click="toSurveyPage">{{ $t('studio.common.popup_case_cf_btn') }}</s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-rating-survey-notify-dialog" />
</template>
<script setup lang="ts">
import safeHtml from '@/components/common/safe-html.vue';

const emits = defineEmits<{
  close: [v: boolean];
}>();

const onClose = () => {
  emits('close', false);
};

const toSurveyPage = async () => {
  emits('close', true);
};
</script>
