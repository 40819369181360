<template>
  <div class="flex gap-24">
    <div class="flex-1">
      <rating-mode-game-basic
        :gameName="rating?.gameName"
        :developer="rating?.developer"
        :defaultGenreTag="rating?.defaultGenreTag"
        :hideGoToDetailButton="mode === RatingPageMode.DETAIL"
      />
      <rating-mode-business-info
        class="mt-24"
        :selectedGroupName="selectedGroupName"
        :businessInfos="businessInfos"
        :ratingManager="ratingManager"
        :hideGoToDetailButton="mode === RatingPageMode.DETAIL"
      />
      <!--  S : 28-1-3.이용등급 및 빌드심사 - 자체심의 (2) -->
      <rating-mode-select-usage-level
        class="mt-24"
        :selectedGrac="selectedGrac"
        :currentRating="currentRating"
        :mode="mode"
        :showSelfReviewOption="showSelfReviewOption"
        :hideDirectInputOption="hideDirectInputOption"
        :hideSelfReviewOption="hideSelfReviewOption"
        @updateSeletedGrac="updateSeletedGrac"
        @onClickObjection="onClickObjection"
      />
      <div class="mt-40 flex justify-center gap-16">
        <s-button
          v-if="
            isProductWritable &&
              mode === RatingPageMode.DETAIL &&
              isBeforeReview &&
              (launchInfoFieldChanged.length > 0 || selectedGrac === GRAC_RADIO.DIRECT_INPUT)
          "
          variant="red"
          size="lg"
          class="!min-w-160"
          @click="onDeleteRating"
        >
          {{ $t('studio.prj_prod_mngmt.rating_build_review.case_info_mismatch.rel_info_del_btn') }}
        </s-button>
        <s-button variant="secondary" size="lg" class="!min-w-160" @click="onNext">
          {{
            selectedGrac === GRAC_RADIO.DIRECT_INPUT && isReleased
              ? $t('studio.build_rating_review.details_edit_btn')
              : $t('studio.prj_prod_mngmt.rating_build_review.self_review.qtn_next_btn')
          }}
        </s-button>
      </div>
    </div>
    <rating-mode-right-wing />
  </div>
  <toast-has-icon
    :text="$t('studio.common.popup_case_f_complete_del')"
    :showing="showToast"
    @update:showing="updateShowing"
  />
</template>
<script setup lang="ts">
import { isEmpty } from 'lodash';
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchCompanyStatusApi } from '@/apis/business-bank-info.api';
import {
  deleteGracRatingApi,
  fetchDraftQuestionnaireReviewApi,
  fetchRatingProductLaunchInfoApi,
  getDetailProductRatingApi,
  postRatingsGlobalProductsApi,
  startSurveyApi
} from '@/apis/rating.api';
import ToastHasIcon from '@/components/common/toast/toast-has-icon.vue';
import RatingSurveyNotifyOpenSurveyDialog from '@/components/rating/dialog/open-survey-dialog.vue';
import RatingModeBusinessInfo from '@/components/rating/mode/business-info.vue';
import RatingModeGameBasic from '@/components/rating/mode/game-basic.vue';
import RatingModeRightWing from '@/components/rating/mode/right-wing.vue';
import RatingModeSelectUsageLevel from '@/components/rating/mode/select-usage-level.vue';
import RatingSurveyDialog from '@/components/rating/rating-survey-dialog.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { MANAGER_TYPE } from '@/constants/business-bank.const';
import { DEFAULT_LANG_CODE, PLATFORM } from '@/constants/common.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { ROLE_ID } from '@/constants/permission.const';
import { GRAC_RADIO, type GracRadioType } from '@/constants/rating.const';
import {
  BUSINESS_BANK_PAGE_URL,
  PRODUCT_LAUNCH_SETTING_PAGE_URL,
  PRODUCT_RATING_GRAC_INPUT_DETAIL_URL,
  PRODUCT_RATING_GRAC_INPUT_EDIT_URL,
  PRODUCT_RATING_URL,
  PRODUCT_SETTING_PAGE_URL
} from '@/constants/url.const';
import { BusinessReviewStatus } from '@/enums/business.enum';
import { Confirmation, MenuAuthorityIds } from '@/enums/common.enum';
import { RatingPageMode, RatingStatus, SurveyDialogType } from '@/enums/rating.enum';
import { useAppStore } from '@/stores/app.store';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { RunOptionGroup } from '@/types/launch-settings/launch-settings.type';
import type { ProductSettingInfo } from '@/types/product/product-model.type';
import type { Iarc, StartSurveyRequest } from '@/types/rating/rating.request.type';
import type {
  BusinessInfoManagers,
  GlobalRatings,
  RatingBusinessInfo,
  RatingProductResponse,
  ReleasedRatings,
  RequestRating
} from '@/types/rating/rating.response.type';
import { getXLang } from '@/utils/api.util';
import { redirectTo, showCommonErrorDialog } from '@/utils/common.util';
import {
  getChangedRatingInformation,
  getCorrectRating,
  isVerifyPassStatus,
  isVerifyRejectStatus
} from '@/utils/rating.util';
import { delayTime } from '@/utils/time.util';

const props = defineProps<{
  mode: RatingPageMode;
}>();

const { checkProductPermission, checkMenuWritable } = useApp();

const { t, locale } = useI18n();
const route = useRoute();

// Product Info
const productStore = useProductStore();

// Business info
const userStore = useUserStore();
const { selectedGroupName } = storeToRefs(userStore);

const appStore = useAppStore();
const { gdsInfo } = storeToRefs(appStore);

const businessInfos = ref<RatingBusinessInfo>();
const ratingManager = ref<BusinessInfoManagers>();
const rating = ref<RatingProductResponse>();
const currentRating = ref<RequestRating | ReleasedRatings>();
const showToast = ref<boolean>(false);
const startedSurvey = ref<boolean>(false);
const hideDirectInputOption = ref<boolean>(false);
const hideSelfReviewOption = ref<boolean>(false);

const surveyLang = computed(() => {
  const xLang = getXLang();

  if (xLang !== DEFAULT_LANG_CODE) {
    return 'en';
  }

  return xLang;
});

const { isProductWritable, isPreRelease, product, productRunOptions, isBeforeReview, isReleased } =
  storeToRefs(productStore);

const { productId, groupId } = route.params;
const { mode } = props;
const showSelfReviewOption =
  !Array.isArray(productRunOptions.value) ||
  productRunOptions.value.length === 0 ||
  productRunOptions.value?.some((item: RunOptionGroup) => item.type === PLATFORM.PC);

const selectedGrac = ref<GracRadioType>(
  showSelfReviewOption ? GRAC_RADIO.SELF_REVIEW : GRAC_RADIO.DIRECT_INPUT
);
const launchInfoFieldChanged = ref<string[]>([]);
const hasSettingChanged = ref(false);

const { validate, errors, setFieldValue, values } = useForm<{
  buildId: string;
  originNation: string;
  productSummary: string;
  intlOrgCheckbox: boolean;
  globalRatings: Iarc[];
}>({
  initialValues: {
    buildId: '',
    originNation: gdsInfo.value?.nation,
    productSummary: '',
    intlOrgCheckbox: false,
    globalRatings: []
  }
});

const productSettingIsCompleted = computed((): boolean => {
  if (product.value && product.value.infos) {
    return product.value.infos.every((info: ProductSettingInfo) => {
      return info.developer && info.productName && info.publisher;
    });
  }
  return false;
});

const updateSeletedGrac = (value: GracRadioType) => {
  selectedGrac.value = value;
};

const onClickObjection = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  const companyStatus = await fetchCompanyStatusApi(groupId as string);

  if (!companyStatus || companyStatus === BusinessReviewStatus.Request) {
    showAlert({
      content: t('studio.prj_prod_mngmt.rating_build_review.self_review.under_review_reg_x')
    });

    return;
  }

  if (hasSettingChanged.value) {
    showAlert({
      content: [
        t(
          'studio.prj_prod_mngmt.rating_build_review.self_review.detailed_pg.appeal_info_edited_popup_msg1'
        ),
        '<br />',
        t(
          'studio.prj_prod_mngmt.rating_build_review.self_review.detailed_pg.appeal_info_edited_popup_msg2'
        )
      ]
    });

    return;
  }

  await showDialog({
    component: shallowRef(RatingSurveyDialog),
    props: {
      type: SurveyDialogType.OBJECTION,
      mode: RatingPageMode.EDIT
    }
  });
};

const onNext = async () => {
  if (selectedGrac.value === GRAC_RADIO.SELF_REVIEW && mode === RatingPageMode.DETAIL) {
    await showDialog({
      component: shallowRef(RatingSurveyDialog),
      props: {
        type: SurveyDialogType.REGISTER,
        mode: props.mode,
        buildId: values.buildId,
        globalRatings: values.globalRatings
      }
    });

    return;
  }

  if (!(await checkProductPermission())) {
    return;
  }
  await validate();
  if (!isEmpty(errors.value)) {
    return;
  }

  if (selectedGrac.value === GRAC_RADIO.DIRECT_INPUT) {
    if (mode === RatingPageMode.EDIT) {
      await postRatingsGlobalProductsApi(productId as string, {
        directYn: Confirmation.YES,
        globalRatings: values.globalRatings
      });
    }

    redirectTo(
      mode === RatingPageMode.DETAIL
        ? PRODUCT_RATING_GRAC_INPUT_DETAIL_URL
        : PRODUCT_RATING_GRAC_INPUT_EDIT_URL
    );
    return;
  }

  if (mode === RatingPageMode.DETAIL) {
    await showDialog({
      component: shallowRef(RatingSurveyDialog),
      props: {
        type: SurveyDialogType.REGISTER,
        mode: props.mode
      }
    });

    return;
  }

  if (!businessInfos.value || !ratingManager.value) {
    const confirm = await showConfirm({
      content: t('studio.prj_prod_mngmt.rating_build_review.self_review.biz_info_not_set_msg'),
      confirmLabel: t('studio.business_info_redirect_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline'
    });

    if (confirm) {
      if (!(await checkMenuWritable({ menuAuthId: MenuAuthorityIds.BIMS_BANK_SETTING }))) {
        return;
      }
      redirectTo(BUSINESS_BANK_PAGE_URL);
    }

    return;
  }

  if (
    !productSettingIsCompleted.value ||
    !rating.value ||
    !rating.value.developer ||
    !rating.value.defaultGenreTagNo
  ) {
    const confirm = await showConfirm({
      content: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.prod_setting_not_completed_guide_popup'
      ),
      confirmLabel: t('studio.direct_prod_setting_pg_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline'
    });

    if (confirm) {
      if (!(await checkProductPermission({ roleId: ROLE_ID.PRODUCT_SETTING }))) {
        return;
      }
      redirectTo(PRODUCT_SETTING_PAGE_URL);
    }

    return;
  }

  const buildInfo: StartSurveyRequest = {
    buildId: values.buildId,
    language: surveyLang.value,
    originNation: values.originNation,
    productSummary: values.productSummary
  };

  const confirmOpenSurvey = await showDialog({
    component: shallowRef(RatingSurveyNotifyOpenSurveyDialog),
    props: {
      buildInfo,
      mode: RatingPageMode.EDIT,
      globalRatings: values.globalRatings,
      productId: productId as string,
      startedSurvey: startedSurvey.value
    }
  });

  if (confirmOpenSurvey) {
    if (startedSurvey.value) {
      await postRatingsGlobalProductsApi(productId as string, {
        directYn: Confirmation.NO,
        globalRatings: values.globalRatings
      });
    } else {
      try {
        await startSurveyApi(productId as string, buildInfo);
        await postRatingsGlobalProductsApi(productId as string, {
          directYn: Confirmation.NO,
          globalRatings: values.globalRatings
        });

        await delayTime(1000); // waiting API process
      } catch (err) {
        const error = err as ErrorResponse;

        if (error.statusCode === STATUS_CODE.PRODUCT_LANGUAGE_MAP_HAS_MISSING_LANGUAGE) {
          const confirm = await showConfirm({
            content: t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.prod_setting_not_completed_guide_popup'
            ),
            confirmLabel: t('studio.direct_prod_setting_pg_btn'),
            cancelLabel: t('studio.common.popup_case_cancel_btn'),
            cancelVariant: 'outline'
          });

          if (confirm) {
            redirectTo(PRODUCT_SETTING_PAGE_URL);
          }
          return;
        }

        if (error.statusCode === STATUS_CODE.RATING_BUILD_OPTION_NOT_ACCEPTABLE) {
          const confirm = await showConfirm({
            content: t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.execution_setting_not_completed_guide_popup'
            ),
            confirmLabel: t('studio.direct_Execution_setting_pg_btn'),
            cancelLabel: t('studio.common.popup_case_cancel_btn'),
            cancelVariant: 'outline'
          });

          if (confirm) {
            await redirectTo(PRODUCT_LAUNCH_SETTING_PAGE_URL);
          }
          return;
        }

        if (error.statusCode === STATUS_CODE.RATING_BUILD_OPTION_OF_PARENT_NOT_ACCEPTABLE) {
          await showAlert({
            content: t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.parent_game_exe_setting_not_completed_guide_popup'
            ),
            confirmLabel: t('studio.common.popup_case_cf_btn')
          });
          return;
        }

        showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
        return;
      }
    }

    await showDialog({
      component: shallowRef(RatingSurveyDialog),
      props: {
        type: SurveyDialogType.REGISTER,
        mode: props.mode,
        buildId: values.buildId,
        globalRatings: values.globalRatings,
        originNation: values.originNation,
        productSummary: values.productSummary
      }
    });
  }
};

const updateShowing = (showing: boolean) => {
  showToast.value = showing;
};

const onDeleteRating = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  const result = await showConfirm({
    content: t(
      'studio.prj_prod_mngmt.rating_build_review.case_info_mismatch.rel_info_del_cf_popup'
    ),
    confirmLabel: t(
      'studio.prj_prod_mngmt.rating_build_review.case_info_mismatch.rel_info_del_cf_btn'
    ),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    cancelVariant: 'outline'
  });

  if (result) {
    await deleteGracRatingApi(productId as string);
    showToast.value = true;
    setTimeout(() => {
      redirectTo(`/${locale.value}${PRODUCT_RATING_URL}`, { external: true });
    }, 1000);
  }
};

const initRatingsProduct = async () => {
  const ratingProduct = await getDetailProductRatingApi(productId as string);
  if (ratingProduct) {
    rating.value = ratingProduct;

    businessInfos.value = ratingProduct.businessInfo;
    businessInfos.value?.managers?.forEach((manager: BusinessInfoManagers) => {
      if (manager.managerType === MANAGER_TYPE.RATING) {
        ratingManager.value = manager;
      }
    });

    const ratingData = getCorrectRating(ratingProduct.requestRating, ratingProduct.releasedRatings);

    const mapGlobalRatings = (ratings: GlobalRatings[]) => {
      return ratings.map((item: GlobalRatings) => ({
        ratingBoardType: item.boardType,
        ageRating: item.ageRating,
        ratingId: item.ratingId
      }));
    };
    if (
      !isEmpty(ratingProduct.releasedRatings) &&
      ratingProduct.releasedRatings[0]?.issuedAt &&
      ratingProduct.releasedGlobalRatings.length > 0
    ) {
      setFieldValue('intlOrgCheckbox', true);
      setFieldValue('globalRatings', mapGlobalRatings(ratingProduct.releasedGlobalRatings));
    } else if (ratingProduct.requestedGlobalRatings.length > 0) {
      setFieldValue('intlOrgCheckbox', true);
      setFieldValue('globalRatings', mapGlobalRatings(ratingProduct.requestedGlobalRatings));
    }

    if (ratingData) {
      rating.value.rating = ratingData;
      currentRating.value = Array.isArray(ratingData) ? ratingData[0] : ratingData;
      if (Array.isArray(ratingData)) {
        selectedGrac.value = ratingData.some(
          (rating: ReleasedRatings) => rating.directYn === Confirmation.YES
        )
          ? GRAC_RADIO.DIRECT_INPUT
          : GRAC_RADIO.SELF_REVIEW;
      }

      if (mode === RatingPageMode.EDIT) {
        if (
          currentRating.value.status === RatingStatus.LINKED &&
          (currentRating.value as ReleasedRatings).directYn === Confirmation.YES
        ) {
          selectedGrac.value = GRAC_RADIO.SELF_REVIEW;
          hideDirectInputOption.value = true;

          setFieldValue('intlOrgCheckbox', false);
          setFieldValue('globalRatings', []);
        } else if (
          isVerifyPassStatus(
            currentRating.value.status,
            (currentRating.value as RequestRating).verifyInfo?.verifyStatus
          ) ||
          (isVerifyRejectStatus(
            currentRating.value.status,
            (currentRating.value as RequestRating).verifyInfo?.verifyStatus
          ) && rating.value.releasedRatings && rating.value.releasedRatings[0] && rating.value.releasedRatings[0].createdAt) ||
          ((currentRating.value as ReleasedRatings).directYn === Confirmation.NO)
        ) {
          hideSelfReviewOption.value = true;
          selectedGrac.value = GRAC_RADIO.DIRECT_INPUT;
        }
      } else {
        let buildId = '';
        if (
          Array.isArray(rating.value.rating) &&
          rating.value.rating.length > 0 &&
          rating.value.rating[0].buildId
        ) {
          buildId = rating.value.rating[0].buildId;
        } else {
          buildId = (rating.value.rating as RequestRating)?.buildId ?? '';
        }
        setFieldValue('buildId', buildId ?? '');
      }
    }
  }
};

const initDraftQuestionnaireReview = async () => {
  if (mode === RatingPageMode.EDIT) {
    if (rating.value && rating.value.rating) {
      let buildId = '';
      if (
        Array.isArray(rating.value.rating) &&
        rating.value.rating.length > 0 &&
        rating.value.rating[0].buildId
      ) {
        buildId = rating.value.rating[0].buildId;
      } else {
        buildId = (rating.value.rating as RequestRating)?.buildId ?? '';
      }

      if (buildId) {
        const res = await fetchDraftQuestionnaireReviewApi(productId as string, {
          buildId,
          languageCd: surveyLang.value
        });
        if (res) {
          startedSurvey.value = true;
          setFieldValue('buildId', res.buildId);
          setFieldValue('originNation', res.contents.originNation);
          setFieldValue('productSummary', res.contents.productSummary);
        }
      }
    }
  }
};

const getLaughInfo = async () => {
  if (mode === RatingPageMode.DETAIL) {
    const launchInfo = await fetchRatingProductLaunchInfoApi(productId as string);
    const changedFields = getChangedRatingInformation(launchInfo, product.value);

    if (changedFields.length > 0) {
      hasSettingChanged.value = true;

      if (isPreRelease.value) {
        launchInfoFieldChanged.value = changedFields;
      }
    }
  }
};

const init = async () => {
  await initRatingsProduct();
  initDraftQuestionnaireReview();
  getLaughInfo();
};

init();
</script>
