<template>
  <st-box class="mt-24">
    <st-form-title
      :formTitle="
        $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.select_rating')
      "
      required
    >
      <s-button
        v-if="isReleased && mode === RatingPageMode.DETAIL && currentRating && selectedSelfReview && (isVerifyPassStatus(currentRating.status, (currentRating as RequestRating).verifyInfo?.verifyStatus) || currentRating.status === RatingStatus.LINKED)"
        size="sm"
        variant="outline"
        @click="onClickObjection"
      >
        {{ $t('studio.prj_prod_mngmt.rating_build_review.self_review.appeal_req_cf_btn') }}
      </s-button>
    </st-form-title>
    <div v-if="showSelfReviewOption" class="mt-12">
      <s-radio
        v-if="!hideSelfReviewOption"
        id="gracSelfReview"
        v-model="selectedSelfReview"
        name="select-grac"
        size="sm"
        align="middle"
        :value="GRAC_RADIO.SELF_REVIEW"
        :isDisabled="mode === RatingPageMode.DETAIL"
        @change="onChangeSelfReview"
      >
        {{
          $t(
            'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.select_rating_grac_self_review'
          )
        }}
      </s-radio>
    </div>
    <!-- Start Grac self review form -->
    <div
      v-if="showSelfReviewOption && selectedSelfReview"
      class="flex flex-col gap-[1rem] pl-24"
      :class="{ 'mt-8': mode === RatingPageMode.EDIT }"
    >
      <template v-if="mode === RatingPageMode.EDIT">
        <div class="flex items-center gap-4">
          <p class="text-sm font-regular leading-md text-on-surface-elevation-4">
            <safe-html
              :html="
                $t(
                  'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.guide'
                )
              "
            />
          </p>
          <s-button
            variant="outline"
            size="xs"
            class="!rounded-full"
            @click="openRatingExampleDialog"
          >
            {{
              $t(
                'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.guide_ex'
              )
            }}
          </s-button>
        </div>
        <div class="flex flex-col gap-16 rounded-2xl bg-neutral-variant-2-pressed p-20">
          <div>
            <s-text as="p" role="btn2" class="text-on-surface-elevation-2 font-regular">
              {{
                $t(
                  'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.build'
                )
              }}
            </s-text>
            <div class="relative">
              <dropdown
                :key="buildIdValue"
                name="buildId"
                :options="buildItems"
                :placeholder="
                  $t(
                    'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.build_place_holder'
                  )
                "
                :emptySearchText="
                  $t(
                    'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.build_no_result'
                  )
                "
                :noDataText="
                  $t('studio.build_rating_review.self_review.build_search_none_registered_build')
                "
                :dropdownProps="{
                  size: 'lg',
                  variant: 'line',
                  distance: 0,
                  offset: [0, 0],
                  placement: 'bottom-start',
                  class: 'w-full mt-4'
                }"
                searchable
                closeOnClick
                :rules="{
                  required: $t(
                    'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.build_required_msg'
                  )
                }"
              />
            </div>
          </div>
          <div>
            <s-text as="p" role="btn2" class="text-on-surface-elevation-2 font-regular">
              {{
                $t(
                  'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.origin_country'
                )
              }}
            </s-text>
            <s-text as="p" role="text4" class="text-on-surface-elevation-4 mt-4">
              {{
                $t(
                  'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.origin_country_guide'
                )
              }}
            </s-text>
            <div class="relative">
              <dropdown
                name="originNation"
                :options="countryOptions"
                :placeholder="
                  $t(
                    'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.origin_country_place_holder'
                  )
                "
                :emptySearchText="$t('studio.group.home.select_product.search_no_result_msg')"
                :dropdownProps="{
                  size: 'lg',
                  variant: 'line',
                  distance: 0,
                  offset: [0, 0],
                  placement: 'bottom-start',
                  class: 'w-full mt-4'
                }"
                searchable
                closeOnClick
                :rules="{
                  required: $t(
                    'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.origin_country_required_msg'
                  )
                }"
                useTranslate
              />
            </div>
          </div>
          <div>
            <s-text as="p" role="btn2" class="text-on-surface-elevation-2 font-regular">
              {{
                $t(
                  'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.description'
                )
              }}
            </s-text>
            <input-text
              name="productSummary"
              size="lg"
              variant="outline"
              :placeholder="
                $t(
                  'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.description_guide'
                )
              "
              maxLength="30"
              :allowInputMaxLength="false"
              containerClass="mt-4"
              :rules="{
                required: $t(
                  'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.description_required_msg'
                )
              }"
            />
          </div>
        </div>
      </template>
      <div v-else class="relative">
        <dropdown
          name="buildId"
          disabled
          :options="buildItems"
          :placeholder="
            $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.build_place_holder'
            )
          "
          :emptySearchText="
            $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.build_no_result'
            )
          "
          :noDataText="
            $t('studio.build_rating_review.self_review.build_search_none_registered_build')
          "
          :dropdownProps="{
            size: 'lg',
            variant: 'line',
            distance: 0,
            offset: [0, 0],
            placement: 'bottom-start',
            class: 'w-full mt-4'
          }"
          searchable
          closeOnClick
          :rules="{
            required: $t(
              'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.grac_self_review.build_required_msg'
            )
          }"
        />
      </div>
    </div>
    <!-- End Grac self review form -->
    <div class="mt-16">
      <s-radio
        v-if="!hideDirectInputOption"
        id="gracRatingNumber"
        v-model="selectedDirectInput"
        name="select-grac"
        size="sm"
        align="middle"
        :value="GRAC_RADIO.DIRECT_INPUT"
        :isDisabled="mode === RatingPageMode.DETAIL"
        @change="onChangeRatingNumber"
      >
        {{ $t('studio.prj_prod_mngmt.rating_build_review.direct_input.title') }}
      </s-radio>
    </div>

    <!-- international classification number -->
    <div class="mt-16 pt-16 border-t-1 border-solid border-border">
      <div class="flex items-center gap-16">
        <div class="flex-1 max-h-[21px]">
          <checkbox
            id="intlOrgCheckbox"
            name="intlOrgCheckbox"
            :options="{
              size: 'sm',
              align: 'middle'
            }"
            :disabled="
              mode === RatingPageMode.DETAIL &&
                (currentRating?.status !== RatingStatus.LINKED || isProductReleaseVerifying)
            "
            @change="onChangeInternationalOrganization"
          >
            {{ $t('studio.prj_prod_mngmt.rating_build_review.rating_reg.intl_org.chkbox') }}
          </checkbox>
        </div>
        <s-button
          v-if="showSaveClassificationNumber"
          variant="primary"
          size="sm"
          class="shrink-0 !min-w-84"
          @click="onSaveClassificationNumber"
        >
          {{ $t('studio.common.popup_case_save_btn') }}
        </s-button>
      </div>
      <international-organization-form
        v-if="intlOrgCheckboxValue"
        name="globalRatings"
        :isDirect="selectedDirectInput"
        :disabled="
          mode === RatingPageMode.DETAIL &&
            (currentRating?.status !== RatingStatus.LINKED || isProductReleaseVerifying)
        "
      />
    </div>
  </st-box>
  <toast-has-icon
    :text="$t('studio.common.popup_case_d_complete_save')"
    icon="ic-v2-control-check-bold-line"
    :showing="showToast"
    @update:showing="updateShowing"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import {
  useFieldError,
  useFieldValue,
  useSetFieldError,
  useSetFieldValue,
  useValidateField
} from 'vee-validate';
import { computed, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchProductBuildUploadCompletedApi } from '@/apis/build.api';
import { fetchProductStatusApi } from '@/apis/products.api';
import { postRatingsGlobalProductsApi } from '@/apis/rating.api';
import safeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import ToastHasIcon from '@/components/common/toast/toast-has-icon.vue';
import ContentPopup from '@/components/rating/mode/content-popup.vue';
import InternationalOrganizationForm from '@/components/rating/mode/international-organization-form.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import { showConfirm, showDialog } from '@/composables/useDialog';
import { SORT_DIRECTION } from '@/constants/common.const';
import { VERIFY_STATUS } from '@/constants/products.const';
import { GRAC_RADIO, type GracRadioType } from '@/constants/rating.const';
import {} from '@/constants/regex.const';
import { Confirmation } from '@/enums/common.enum';
import { RatingPageMode, RatingStatus } from '@/enums/rating.enum';
import { useAppStore } from '@/stores/app.store';
import { useCountryStore } from '@/stores/country.store';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { Build, FetchProductBuildParams } from '@/types/build/build.type';
import type { FormOptionGroup } from '@/types/common/form.type';
import type { Iarc } from '@/types/rating/rating.request.type';
import type { ReleasedRatings, RequestRating } from '@/types/rating/rating.response.type';
import { isVerifyPassStatus } from '@/utils/rating.util';

const PAGE_SIZE_BUILD = 1000; // TOTO need apply lazy loading for build list when real data ready

const props = defineProps<{
  selectedGrac: GracRadioType;
  currentRating?: RequestRating | ReleasedRatings;
  mode: RatingPageMode;
  showSelfReviewOption: boolean;
  hideDirectInputOption: boolean;
  hideSelfReviewOption: boolean;
}>();

const emit = defineEmits<{
  updateSeletedGrac: [gracType: GracRadioType];
  onClickObjection: [];
}>();

const appStore = useAppStore();
const { gdsInfo } = storeToRefs(appStore);

const { t } = useI18n();
const route = useRoute();
const countryStore = useCountryStore();
const userStore = useUserStore();
const productStore = useProductStore();
const buildIdValue = useFieldValue<string>('buildId');
const setBuildIdValue = useSetFieldValue('buildId');
const setOriginNationValue = useSetFieldValue('originNation');
const globalRatingsValue = useFieldValue<Iarc[]>('globalRatings');
const intlOrgCheckboxValue = useFieldValue<boolean>('intlOrgCheckbox');
const setGlobalRatingsValue = useSetFieldValue('globalRatings');
const setGlobalRatingsError = useSetFieldError('globalRatings');
const globalRatingsError = useFieldError('globalRatings');
const validateGlobalRatingsField = useValidateField('globalRatings');

const { selectedGroupId } = storeToRefs(userStore);
const { product, isReleased } = storeToRefs(productStore);
const gameId = product.value?.gameId || '';

const { projectId, productId } = route.params;
const selectedSelfReview = ref(props.selectedGrac === GRAC_RADIO.SELF_REVIEW);
const selectedDirectInput = ref(props.selectedGrac === GRAC_RADIO.DIRECT_INPUT);
const isProductReleaseVerifying = ref(false);

const showToast = ref<boolean>(false);

// @TODO : dropdown 에서 선택된 빌드 아이템
const buildItems = ref<FormOptionGroup<string | number>[]>([]);
const buildSearchParams = ref<FetchProductBuildParams>({
  teamId: selectedGroupId.value,
  projectId: projectId as string,
  gameId,
  sort: 'CREATED_AT',
  direction: SORT_DIRECTION.DESC,
  searchValue: '',
  page: 1,
  size: PAGE_SIZE_BUILD
});

const { countryOptions } = storeToRefs(countryStore);

const showSaveClassificationNumber = computed(() => {
  return (
    props.mode === RatingPageMode.DETAIL &&
    !isProductReleaseVerifying.value &&
    (selectedDirectInput.value ||
      (selectedSelfReview.value && props.currentRating?.status === RatingStatus.LINKED))
  );
});

const openRatingExampleDialog = async () => {
  await showDialog({
    component: shallowRef(ContentPopup),
    severity: 'info'
  });
};

const onClickObjection = async () => {
  emit('onClickObjection');
};

const resetInternationalOrganization = () => {
  setGlobalRatingsValue([]);
  setGlobalRatingsError(undefined);
};

// const onInputBuildName = (e: Event) => {
//   const target = e.target as HTMLInputElement | null;
//   if (target) {
//     buildSearchParams.value.searchValue = target.value;
//   }
//   buildSearchParams.value.page = 1;
//   searchBuild();
// };

const onChangeInternationalOrganization = () => {
  if (!intlOrgCheckboxValue.value) {
    resetInternationalOrganization();
  }
};

const onChangeSelfReview = async () => {
  selectedDirectInput.value = false;

  const ratingBoardType = globalRatingsValue.value.map((item: Iarc) => item.ratingBoardType);
  const isDuplicate = ratingBoardType.length !== new Set(ratingBoardType).size;
  if (isDuplicate) {
    const confirm = await showConfirm({
      content: t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.rating_num_exceed_case3_popup'
      ),
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline'
    });

    if (confirm) {
      resetInternationalOrganization();
    } else {
      selectedDirectInput.value = true;
      selectedSelfReview.value = false;
      return;
    }
  }

  if (buildItems.value[0].value) {
    setBuildIdValue(buildItems.value[0].value);
    setOriginNationValue(gdsInfo.value?.nation);
  }

  emit('updateSeletedGrac', GRAC_RADIO.SELF_REVIEW);
};

const onChangeRatingNumber = () => {
  selectedSelfReview.value = false;
  emit('updateSeletedGrac', GRAC_RADIO.DIRECT_INPUT);
};

const onSaveClassificationNumber = async () => {
  await validateGlobalRatingsField();
  if (globalRatingsError.value) {
    return;
  }

  const confirm = await showConfirm({
    content: t('studio.prj_prod_mngmt.rating_build_review.intl_org_save_msg'),
    confirmLabel: t('studio.common.popup_case_cf_btn'),
    isCancelButtonVisible: true,
    cancelVariant: 'outline'
  });

  if (confirm) {
    try {
      const res = await postRatingsGlobalProductsApi(productId as string, {
        directYn: selectedDirectInput.value ? Confirmation.YES : Confirmation.NO,
        globalRatings: globalRatingsValue.value
      });

      if (res) {
        showToast.value = true;
      }
    } catch (error) {}
  }
};

const searchBuild = async () => {
  const buildRes = await fetchProductBuildUploadCompletedApi({
    gameId: buildSearchParams.value.gameId
  });
  if (buildRes) {
    buildItems.value = buildRes.map((item: Build) => ({
      label: `${item.buildNo} (${item.buildDescription || '-'})`,
      value: item.buildId
    }));

    if (!buildIdValue.value && buildRes.length > 0) {
      setBuildIdValue(buildItems.value[0].value);
    }
  }
};

const getProductReleaseStatusApi = async () => {
  const releasePlanRes = await fetchProductStatusApi(Number(productId));
  if (releasePlanRes && releasePlanRes.verifyStatus) {
    isProductReleaseVerifying.value =
      releasePlanRes.verifyStatus === VERIFY_STATUS.START ||
      releasePlanRes.verifyStatus === VERIFY_STATUS.REQUEST;
  }
};

const updateShowing = (showing: boolean) => {
  showToast.value = showing;
};

watch(
  () => props.selectedGrac,
  (value: GracRadioType) => {
    selectedSelfReview.value = value === GRAC_RADIO.SELF_REVIEW;
    selectedDirectInput.value = value === GRAC_RADIO.DIRECT_INPUT;
  }
);

const init = async () => {
  searchBuild();
  getProductReleaseStatusApi();
};

init();
</script>
