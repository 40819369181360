<template>
  <st-box>
    <st-folding-evaluate
      :evaluateTitle="
        $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.game_info_title')
      "
      :modifyUse="!hideGoToDetailButton"
      :modifyUseTitle="
        $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.info_edit_btn')
      "
      :isOpenDetail="true"
      @onModify="onModify"
    >
      <div
        class="grid grid-cols-2 gap-y-20 gap-x-8 bg-[var(--stds-glob-color-gray20)] rounded-3xl p-20"
      >
        <st-folding-evaluate-item
          class="col-span-2"
          :titleValue="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_basic_name')
          "
          :dataValue="gameName || '-'"
        />
        <st-folding-evaluate-item
          class="col-span-2"
          :titleValue="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_basic_company')
          "
          :dataValue="developer || '-'"
        />
        <st-folding-evaluate-item
          class="col-span-2"
          :titleValue="
            $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_info_basic_genre')
          "
          :dataValue="defaultGenreTag || '-'"
        />
      </div>
    </st-folding-evaluate>
  </st-box>
</template>

<script setup lang="ts">
import StBox from '@/components/common/st-box.vue';
import StFoldingEvaluate from '@/components/common/st-folding-evaluate.vue';
import StFoldingEvaluateItem from '@/components/common/st-folding-evaluate-item.vue';
import { useApp } from '@/composables/useApp';
import { ROLE_ID } from '@/constants/permission.const';
import { PRODUCT_SETTING_PAGE_URL } from '@/constants/url.const';
import { redirectTo } from '@/utils/common.util';

defineProps<{
  gameName?: string;
  developer?: string;
  defaultGenreTag?: string;
  hideGoToDetailButton?: boolean;
}>();

const { checkProductPermission } = useApp();

const onModify = async () => {
  if (!(await checkProductPermission({ roleId: ROLE_ID.PRODUCT_SETTING }))) {
    return;
  }
  redirectTo(PRODUCT_SETTING_PAGE_URL);
};
</script>
